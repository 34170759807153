import Vue from 'vue'
import VueRouter from 'vue-router'
// import MapView from '../views/MapView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/map3'
  },
  // {
  //   path: '/map',
  //   name: 'map',
  //   component: MapView
  // },
  // {
  //   path: '/map1',
  //   name: 'map1',
  //   component: ()=>import('../views/MapView1.vue')
  // },
  // {
  //   path: '/map2',
  //   name: 'map2',
  //   component: ()=>import('../views/MapView2.vue')
  // },
  {
    path: '/map3',
    name: 'map3',
    component: ()=>import('../views/MapView3.vue')
  },

]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  routes
})

export default router
